import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../actions';
import * as _ from 'lodash';
import { Select, Input, Button, Divider } from 'antd';
import PageContent from './PageContent';
import ReCAPTCHA from "react-google-recaptcha";

const selectUser = state => state.user;
const selectContent = state => state.content;

function Content({ container, userId }) {
  const recaptchaRef = useRef(null)

  const { Option } = Select;
  
  const dispatch = useDispatch();
  const TOKEN_GEN_ROLE = 950;
  const { loadContent, genToken, updateTokenDuration } = bindActionCreators(actionCreators, dispatch);

  
  const userData = useSelector(selectUser);
  const content = useSelector(selectContent);
  const [captchaToken, setCaptchaToken] = useState(null);
  const userRoles = _.get(userData, 'roles', []);

  const onChangeCaptcha = (value) => {
    setCaptchaToken(value)
  }

  const updateDuration = (data) => {
    updateTokenDuration({val: data});
  };

  const sendEmail = () => {
    if (content.sendingToken) {
      return false;
    }
    let sendTo = `${document.getElementById('email-id').value}`;
    if (sendTo && sendTo.includes('@') && sendTo.includes('.')) {
      console.log(`sending to email`, sendTo);
      genToken({
        sendType: 'email',
        url: window.location.host,
        sendTo,
        tokenDuration: content.tokenDuration
      });
    } else {
      alert('Invalid Email!');
    }
  };

  const sendSMS = () => {
    if (content.sendingToken) {
      return false;
    }
    let sendTo = `${document.getElementById('phone-id').value}`;
    if (sendTo && sendTo.length >= 10) {
      console.log(`sending to phone`, sendTo);
      genToken({
        sendType: 'phone',
        url: window.location.host,
        sendTo,
        tokenDuration: content.tokenDuration
      });
    } else {
      alert('Invalid Phone!');
    }
  };

  const sendClipboard = () => {
    if (content.sendingToken) {
      return false;
    }
    let sendTo = `${document.getElementById('name-id').value}`;
    if (sendTo && sendTo.length > 0) {
      console.log(`sending to clipboard`, sendTo);
      genToken({
        sendType: 'clipboard',
        url: window.location.host,
        sendTo,
        tokenDuration: content.tokenDuration
      });
    } else {
      alert('Invalid Name!');
    }
  };

  const verifyAndLoad = () => {

    if (content.contentLoading) {
      return false;
    }

    let emailOrName = `${document.getElementById('verify-textbox').value}`;

    let savedEmailOrName = localStorage.getItem('emailOrName');
    if (
      !(emailOrName && `${emailOrName}`.trim().length > 0) &&
      savedEmailOrName && `${savedEmailOrName}`.length > 0) {
      emailOrName = savedEmailOrName;
    }

    if (emailOrName && `${emailOrName}`.trim().length > 0) {

      if (!(captchaToken && !_.isEmpty(captchaToken))) {
        alert("Please verify you are a human");
        return false;
      }

      loadContent({
        urltoken: localStorage.getItem('urltoken') || '',
        userId: '',
        emailOrName,
        captchaToken
      });
      try {
        recaptchaRef.current.reset();
      } catch (e) {}
    } else {
      alert('Invalid Name or Email!');
    }
    
  };

  useEffect(() => {
    if (content.tryLoading && (!content.contentLoading) && userId && userId.length > 0) {
      loadContent({
        urltoken: localStorage.getItem('urltoken') || '',
        userId: userId || '',
        emailOrName: ''
      });
    }
  }, [loadContent, userId, content]);

  let debug = `${localStorage.getItem('debug')}` === '1' ? 
    <div className="tokengen">
      Container: <b>{container}</b><br/>
      UserId: <b>{userId}</b><br/>
      Token: <b>{localStorage.getItem('urltoken')}</b>
    </div> : <span></span>;

  const getTokenGen = () => {
    return <div className="tokengen">
    <div className="title">Generate Tokens { content.sendingToken && <span>Sending...</span> }</div>
    <div className='subtitle'>Create and send links with tokens to the end user for accessing the website below.</div>
      <div className="optionrow">
        <span className="label">Link Expiration</span>
        <div>
          <Select style={{width: '250px', color: '#777'}} defaultValue={90} onChange={updateDuration}>
            <Option key={1} value={7} >1 Week</Option>
            <Option key={2} value={14} >2 Weeks</Option>
            <Option key={3} value={30} >1 Month</Option>
            <Option key={4} value={60} >2 Months</Option>
            <Option key={5} value={90} >3 Months</Option>
          </Select>
        </div>
      </div>
      <Divider dashed orientation="left" style={{color: '#888', fontFamily: 'BrixSansLight'}}>Option 1</Divider>
      <div className="optionrow">
        <span className="label">Send to Email</span>
        <div>
          <Input id='email-id' placeholder='sample@sample.com' style={{width: '250px', marginRight: '25px', color: '#777'}}></Input>
          <div className='hint'>This email address will be required for verification before accessing the website. Please ensure it's communicated.</div>
        </div>
        <Button onClick={sendEmail} className={ content.sendingToken ? 'sending-message' : '' }>SEND</Button>
      </div>
      <Divider dashed orientation="left" style={{color: '#888', fontFamily: 'BrixSansLight', display: 'none'}}>Option 2</Divider>
      <div className="optionrow" style={{display: 'none'}}>
        <span className="label">Send to Phone</span>
        <div>
          <Input id='phone-id' placeholder='+18887776666' style={{width: '250px', marginRight: '25px', color: '#777'}}></Input>
          <div className='hint'>This phone will be required for verification before accessing the website. Please ensure it's communicated.</div>
        </div>
        <Button onClick={sendSMS} className={ content.sendingToken ? 'sending-message' : '' }>SEND</Button>
      </div>
      <Divider dashed orientation="left" style={{color: '#888', fontFamily: 'BrixSansLight'}}>Option 2</Divider>
      <div className="optionrow">
        <span className="label">Copy to clipboard</span>
        <div>
          <Input id='name-id' placeholder='First Name & Last Name' style={{width: '250px', marginRight: '25px', color: '#777'}}></Input>
          <div className='hint'>This name will be required for verification before accessing the website. Please ensure it's communicated.</div>
        </div>
        <Button onClick={sendClipboard} className={ content.sendingToken ? 'sending-message' : '' }>COPY</Button>
      </div>
    </div>
  };

  const getVerificationForm = () => {
    let emailOrName = localStorage.getItem('emailOrName');
    return <div className="training-wrapper verification" style={{opacity: content.contentLoading ? '0.5' : '1' }}>
        <div className="tokengen">
        <div className="title">Welcome!</div>
        <div className='subtitle'>Before entering, let's verify your access.</div>
        <div className="optionrow">
          <span className="label">Enter Email or Name:</span>
          <div>
            <Input id='verify-textbox' placeholder={emailOrName || ''} style={{width: '250px', marginRight: '25px', color: '#777'}}></Input>
            <div className='hint'>Enter the email address or name linked to your token to enter the website.</div>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={onChangeCaptcha}
          />
          <Button size='large' type='primary' onClick={verifyAndLoad} className={ content.contentLoading ? 'sending-message' : '' }>{content.contentLoading ? 'VERIFYING...' : 'VERIFY'}</Button>
        </div>
      </div>
    </div>
  };

  if (content.tryLoading || content.retryLoading) {
    if (userId && userId.length > 0) {
      return <div className="training-wrapper">{debug}</div>
    } else {
      return getVerificationForm();
    }
  } else {
    return (
      <>
        <div className="training-wrapper">
          {debug}
          { userRoles.includes(TOKEN_GEN_ROLE) && (getTokenGen()) }
        </div>
        { content && content.content && content.content.page && <PageContent {...{userId}}/> }
      </>
    );
  }


}

export default Content;
